
 
 
 .who{
      height: 50vh;
      background-color: white; 
 }


 .vertical{
border-left: 2px solid #000;
  height: 655px;
  margin-left: 70px;
  margin-top: 10px;
}
.we-are{
      padding: 50px;
      /* background-color: blue; */
}
.are{
      padding: 50px;
}
.are p{
      font-weight: lighter;
      margin-top: 10px;
      margin-left: 10px;
      font-family: cormorant garamond;
}
.padd{
      padding: 60px;
}
.system{
      padding: 15px;
      /* width: 80%; */
      background-color: #528FFC;
      text-align: center;
}
.system h1{
      font-family: libre baskerville;
      font-size: 57px;
}
.confidential{
      padding: 50px;
}
.confidential p{
      font-weight: lighter;
      margin-top: 10px;
      margin-left: 10px;
      font-family: cormorant garamond;
}
.title{
      font-family: libre baskerville;
      font-size:77px;
}
.titre{
      font-family: libre baskerville;
      font-size:77px;
      text-align: center;   
}
.we-are p{
      font-weight: lighter;
      margin-top: 10px;
      margin-left: 10px;
      font-family: cormorant garamond;
}
.area{
      display: flex;
}
.vmc{
      display: flex;
      padding: 80px;
      gap: 155px;
}
.vision{
      width: 30%;
      /* background-color: blue; */
}
.vision h1{
      font-family:libre baskerville;
      text-align: center;
}
.icon{
display: flex;
height: 20vh;
width:100%;
/* background-color: aquamarine; */
justify-content: center; 
align-items: center;     
}
.icon img{
max-width: 100%;         
max-height: 100%; 
}

.vision p{
      font-weight: lighter;
      font-family: cormorant garamond;
      margin-top: 10px;
}
.vertical-1{
      border-left: 2px solid #000;
        height: 380px;
        margin-left: 70px;
        margin-top: 10px;
      }
      .carousel-container{
            padding: 17px;
            /* background-color: aqua; */
      }

      @media(max-width:820px){
            .vmc{
              
                /* display: grid; */
                /* grid-template-columns: repeat(1,1fr); */
                padding: 65px;
               text-align: center;
               gap: 35px;
            }
            
            .vision{
                 
                  width: 100%;
                  margin-top: 13px;
                  
              }  
              .vertical{
                    height: 540px;
                   
                  }
              .vision p{
                  font-weight: lighter;
                  font-family: cormorant garamond;
                  margin-top: 10px;
                  text-align: left;
            }
              .title{
                  font-family: libre baskerville;
                  font-size:35px;
            }
            .vertical-1{
                  border-left: 2px solid #000;
                    height: 260px;
                    margin-left: 70px;
                    margin-top: 10px;
                  }
      }
      @media(max-width:550px){
            .anime img{
                  height: 23vh;
            }
            .vmc{
              
                display: grid;
                grid-template-columns: repeat(1,1fr);
                padding: 60px;
               text-align: center;
               gap: 15px;
               /* background-color: aqua; */
            }
            .system h1{
                  font-family: libre baskerville;
                  font-size: 35px;
            }
            .padd{
      padding: 30px;
      /* background-color: black; */
}
            .vision{
                 
                  width: 100%;
                  margin-top: 13px;
                  
              }  
              .vertical{
                    height: 460px;
                   
                  }
              .vision p{
                  font-weight: lighter;
                  font-family: cormorant garamond;
                  margin-top: 10px;
                  text-align: left;
            }
              .title{
                  font-family: libre baskerville;
                  font-size:35px;
            }
            .titre{
                  font-family: libre baskerville;
                  font-size:35px;
            }
            .are{
                  padding: 20px;
            }
            .confidential{
                  padding: 15px;
            }
            .vertical-1{
                  border-left: 2px solid #000;
                    height: 365px;
                    margin-left: 60px;
                    margin-top: 0px;
                  }
                 
      }
            @media(max-width:414px){
                  .vmc{
                    
                      display: grid;
                      grid-template-columns: repeat(1,1fr);
                      padding: 65px;
                     text-align: center;
                     gap: 15px;
                  }
                  .vision{
                       
                        width: 100%;
                        margin-top: 13px;
                        /* background-color: green; */
                        
                    }  
                    .vertical{
                          height: 440px;
                         
                        }
                   .vertical-1{
                              border-left: 2px solid #000;
                                height: 300px;
                                margin-left: 40px;
                                margin-top: 0px;
                              }
                    .vision p{
                        font-weight: lighter;
                        font-family: cormorant garamond;
                        margin-top: 10px;
                        text-align: left;
                  }
                    .title{
                        font-family: libre baskerville;
                        font-size:27px;
                  }
                  .titre{
                        font-family: libre baskerville;
                        font-size:35px;
                  }}

                  @media(max-width:360px){
                        .vertical-1{
                              border-left: 2px solid #000;
                                height: 335px;
                                margin-left: 40px;
                                margin-top: 0px;
                              }
                  }