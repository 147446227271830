.carousel-item-content{
  display: flex;
  /* background-color: rgb(136, 104, 104); */
  height: 50vh;
  padding: 50px;
}
.carousel-item-content img{
  padding-left: 120px;
}
.caption{
 padding: 40px;
 width: 48%;
}
.caption h3{
  font-family: libre baskerville;
      font-size:37px;
}
.caption p{
  font-weight: lighter;
      margin-top: 10px;
      margin-left: 10px;
      font-family: cormorant garamond;
}
.carousel-control-prev-icon{
  color: black; /* Change this to the color you want */
  /* background-size: 100%, 100%; */
  /* font-size: 150px; */
  background-image: none;

}
.carousel-control-next-icon {
  color: black; /* Change this to the color you want */
  /* background-size: 100%, 100%; */
  /* font-size: 150px; */
  background-image: none;

}

.carousel-control-prev-icon::after {
  content: '\2039'; /* Unicode character for left arrow */
  /* margin-right: 130px; */
font-size: 70px;
  color: rgb(0, 0, 0); /* Change this to the color you want */
}

.carousel-control-next-icon::after {
  content: '\203A'; /* Unicode character for right arrow */
 font-size: 70px;
/* margin-right: 10px; */
  color: rgb(0, 0, 0); /* Change this to the color you want */
}

@media(max-width:1024px){
  .carousel-item-content{
    display: grid;
    grid-template-columns: repeat(1,1fr);
    padding: 10px;
    height: 60vh;
   text-align: center;
   gap: 0px;
}
.carousel-item-content img{
  padding-left: 5px;
  width: 100%;
}

.carousel-control-prev-icon::after {
  content: '\2039'; /* Unicode character for left arrow */
  /* margin-right: 130px; */
font-size: 160px;
  color: rgb(0, 0, 0); /* Change this to the color you want */
}

.carousel-control-next-icon::after {
  content: '\203A'; /* Unicode character for right arrow */
 font-size: 160px;
/* margin-right: 10px; */
  color: rgb(0, 0, 0); /* Change this to the color you want */
}
.caption{
  padding: 30px;
  width: 100%;
 }
 .caption h3{
  font-family: libre baskerville;
      font-size:27px;
}}
@media(max-width:550px){
  .carousel-item-content{
      display: grid;
      grid-template-columns: repeat(1,1fr);
      padding: 10px;
      height: 55vh;
     text-align: center;
     gap: 0px;
  }
  .carousel-item-content img{
  margin-top: 0px;
    padding-left: 5px;
    width: 100%;
    /* background: red; */
  }
  .carousel-control-prev-icon::after {
    content: '\2039'; /* Unicode character for left arrow */
    /* margin-right: 130px; */
  font-size: 70px;
    color: rgb(0, 0, 0); /* Change this to the color you want */
  }
  
  .carousel-control-next-icon::after {
    content: '\203A'; /* Unicode character for right arrow */
   font-size: 70px;
  /* margin-right: 10px; */
    color: rgb(0, 0, 0); /* Change this to the color you want */
  }
  .caption{
    /* background-color: aqua; */
    padding: 30px;
    width: 100%;
   }
   .caption h3{
    font-family: libre baskerville;
        font-size:27px;
  }
 }