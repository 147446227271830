*{
    margin: 0;
    padding: 0;
}
.menu-container a{
    color: black;
}
.collapsible {
    background-color: #eee;
    color: #444;
    cursor: pointer;
    padding: 18px;
    width: 100%;
    border: none;
    text-align: left;
    outline: none;
    font-size: 15px;
  }
  .activeColl, .collapsible:hover {
    background-color: #ccc;
  }
.content {
    padding: 0 18px;
    display: none;
    /* overflow: hidden; */
    background-color: #f1f1f1;
  }
