.headerContainer {
    background-color: white;
    /* height: 15vh; */
    /* box-shadow: rgba(128, 128, 128, 0.534) 1px 0px 3px; */
    /* padding: 10px; */
    display: flex;
    justify-content: space-between;
    /* align-items: center; */

}
.logo{
    padding-left: 5px;
}
.logo img {
    height: 15vh;
    width: 100%;
    object-fit: cover;
    

}

.Navbar {
    /* background-color: blue; */
    display: flex;
    width:45%;
    /* margin-top: 15px; */
    /* padding: 13px; */
    /* margin-left: 70px; */
}

.Navbar a {
    /* margin-right: 0px; */
    /* float: right; */
    padding: 40px;
    color: black;
    text-decoration: none;
    font-size: 16px;
    font-weight: 100;
    position: relative;
    font-family: cormorant garamond;

}

.Navbar a:after {
    background-color: black;
    content: "";
    /* height: 2px;
    left: 12%;
    bottom: 27px;
    position: absolute;
    transition: 0.3s; */
}

.Navbar a:hover {
    /* border-bottom: 2px solid black; */
    color: black;
    cursor: pointer;
}

.Navbar a:hover:after {
    width: 75%;
}
.button-container{
    display: none;
    cursor: pointer;
}

@media(max-width:912px) {
    .Navbar a {
        padding: 25px 10px;
    }
    .logo img {
        height: 12vh;
        object-fit: contain;
        
    }

}
@media(max-width:700px) {
    .Navbar a {
        display:none;
    }
    .button-container{
        display: block;
        
    }
    
    .logo img {
        height: 25vh;
        object-fit: contain;
        
    }
    
}
@media(max-width:414px) {
    .logo img {
        object-fit: contain;
        max-height: 13.5vh;
        
        
    }
    
}